import React from 'react';
import Header from './containers/Header';

const App: React.FC = () => {
  return (
    <div>
      <Header></Header>
    </div>
  );
};

export default App;
