import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { Player } from '@lottiefiles/react-lottie-player';

interface IHeader {}

const Header: React.FC<IHeader> = () => {
  const blobCursor = () => {
    const hero = document.querySelector('.hero-secondary');
    const tl = gsap.timeline({ delay: 1 });

    tl.to(hero, {
      '--maskSize1': '16%',
      duration: 0.5,
      ease: 'back.out(2)',
    }).to(hero, {
      '--maskSize2': '24%',
      '--maskSize3': 'calc(24% + 0.1rem)',
      duration: 0.5,
      delay: 0.5,
      ease: 'back.out(2)',
    });

    window.addEventListener('mousemove', (e) => {
      const { clientX, clientY } = e;
      const x = Math.round((clientX / window.innerWidth) * 100);
      const y = Math.round((clientY / window.innerHeight) * 100);

      gsap.to(hero, {
        '--x': `${x}%`,
        '--y': `${y}%`,
        duration: 0.3,
        ease: 'sine.out',
      });
    });
  };

  useEffect(() => {
    blobCursor();
  }, []);
  return (
    <section>
      {window.innerWidth > 768 ? (
        <div className="wrapper">
          <div className="hero">
            <Player
              src={'assets/animation/night.json'}
              className="w-screen"
              autoplay
              loop
            />
          </div>
          <div className="hero hero-secondary" aria-hidden="true">
            <Player
              src={'assets/animation/day.json'}
              className="w-screen"
              autoplay
              loop
            />
          </div>
        </div>
      ) : (
        <Player
          src={'assets/animation/mobile.json'}
          className="w-screen"
          autoplay
          loop
        />
      )}
    </section>
  );
};

export default Header;
